import { APIBaseUrl } from "./constants";

export const postLog = (user_id, program, note, sub_id, view_id, alert_id, cnt=0) => {
    let headers = {
        "Content-Type": "application/json",
        'Accept': 'application/json'
    };
    if(!user_id) user_id=-1;
    if(!sub_id) sub_id='';
    if(!view_id) view_id='';
    if(!note) note='';
    if(!alert_id) alert_id='';
    let body;
    body = JSON.stringify({
        user_id, program, note, sub_id, view_id, alert_id, cnt
    })
    return fetch(`${APIBaseUrl}/log/`, { headers, body, method: "POST" })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                return {status: res.status}
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res;
            } else {
                return {status: res.status}
            }
        })
}
