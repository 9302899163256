import { postLog } from '../utils/log';
import { APIBaseUrl } from '../utils/constants';


let marker = null;

/* removed these in February, 2024 
    1001,1041,1042,1051,1052,1058,1071,1072,1073,1020,1021
*/
export let mapPinStyles =
{
    1: { icon: "/images/alertIcons/red-circle-border-n-24.png", anchor: { x: 12, y: 12 } },
    2: { icon: "/images/alertIcons/st_google_marker4a.png" },
    3: { icon: "/images/alertIcons/NOAA_marker.png", anchor: { x: 16, y: 16 } },
    4: { icon: "/images/alertIcons/green-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    5: { icon: "/images/alertIcons/blue-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    6: { icon: "/images/alertIcons/usgs.gov.png", anchor: { x: 16, y: 16 } },
    // 7: { icon: "/images/alertIcons/green-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    8: { icon: "/images/alertIcons/green-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    9: { icon: "/images/alertIcons/yellow-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    10: { icon: "/images/alertIcons/red-x.png", anchor: { x: 16, y: 16 } },
    // 1001: { icon: "/images/alertIcons/green-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    // 1020: { icon: "/images/alertIcons/pink-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    // 1021: { icon: "/images/alertIcons/green-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    // 1041: { icon: "/images/alertIcons/green-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    // 1042: { icon: "/images/alertIcons/blue-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    // 1051: { icon: "/images/alertIcons/green-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    // 1052: { icon: "/images/alertIcons/blue-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    // 1058: { icon: "/images/alertIcons/red-square-border-p-24.png", anchor: { x: 12, y: 12 } },
    1060: { icon: "/images/alertIcons/red-square-border-p-24.png", anchor: { x: 12, y: 12 } },
    // 1071: { icon: "/images/alertIcons/green-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    // 1072: { icon: "/images/alertIcons/pink-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    // 1073: { icon: "/images/alertIcons/blue-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    1080: { icon: "/images/alertIcons/red-square-border-p-24.png", anchor: { x: 12, y: 12 } },
    // 1091: { icon: "/images/alertIcons/green-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    // 1090: { icon: "/images/alertIcons/pink-square-border-og-24.png", anchor: { x: 12, y: 12 } },
    2001: { icon: "/images/alertIcons/red-square-border-p-24.png", anchor: { x: 12, y: 12 } },
    // 6001: { icon: "/images/alertIcons/st_white_swoosh_on_slicks.png", anchor: { x: 12, y: 12 } },
    10101: { icon: "/images/alertIcons/st_white_swoosh_on_permit_approved.png", anchor: { x: 12, y: 12 } },
    // 10102: { icon: "/images/alertIcons/st_white_swoosh_on_nrc.png", anchor: { x: 12, y: 12 } },
    10102: { icon: "/images/alertIcons/st_white_swoosh_on_violations.png", anchor: { x: 12, y: 12 } },
    10103: { icon: "/images/alertIcons/ukraine_flag_marker2.png", anchor: { x: 12, y: 12 } },
}


export const states = [
    { value: "127", label: "Alaska" },
    { value: "128", label: "Alabama" },
    { value: "129", label: "Arkansas" },
    { value: "130", label: "Arizona" },
    { value: "131", label: "California" },
    { value: "132", label: "Colorado" },
    { value: "133", label: "Connecticut" },
    { value: "134", label: "District of Columbia" },
    { value: "135", label: "Delaware" },
    { value: "136", label: "Florida" },
    { value: "137", label: "Georgia" },
    { value: "138", label: "Hawaii" },
    { value: "139", label: "Iowa" },
    { value: "140", label: "Idaho" },
    { value: "141", label: "Illinois" },
    { value: "142", label: "Indiana" },
    { value: "143", label: "Kansas" },
    { value: "144", label: "Kentucky" },
    { value: "145", label: "Louisiana" },
    { value: "146", label: "Massachusetts" },
    { value: "147", label: "Maryland" },
    { value: "148", label: "Maine" },
    { value: "149", label: "Michigan" },
    { value: "150", label: "Minnesota" },
    { value: "151", label: "Missouri" },
    { value: "152", label: "Mississippi" },
    { value: "153", label: "Montana" },
    { value: "154", label: "North Carolina" },
    { value: "155", label: "North Dakota" },
    { value: "156", label: "Nebraska" },
    { value: "157", label: "New Hampshire" },
    { value: "158", label: "New Jersey" },
    { value: "159", label: "New Mexico" },
    { value: "160", label: "Nevada" },
    { value: "161", label: "New York" },
    { value: "162", label: "Ohio" },
    { value: "163", label: "Oklahoma" },
    { value: "164", label: "Oregon" },
    { value: "2", label: "Pennsylvania" },
    { value: "165", label: "Puerto Rico" },
    { value: "166", label: "Rhode Island" },
    { value: "167", label: "South Carolina" },
    { value: "168", label: "South Dakota" },
    { value: "169", label: "Tennessee" },
    { value: "170", label: "Texas" },
    { value: "171", label: "Utah" },
    { value: "172", label: "Virginia" },
    { value: "173", label: "Vermont" },
    { value: "174", label: "Washington" },
    { value: "1", label: "West Virginia" },
    { value: "175", label: "Wisconsin" },
    { value: "176", label: "Wyoming" },
]


export const getAlertForReport = async (id, setReportDetail, history, alertsMap, user_id, setZoom) => {
    if (!id) return null;
    let headers = {
        "Content-Type": "application/json",
    };
    // the id will contain the alert id (feedentry) and if 'sub' is in the string, also the RSSEmailSubscription id. 
    let uPos = id.indexOf('sub')
    let subId = '';
    if(uPos > 0) {
        subId = id.substr(uPos + 3);
        id = id.substr(0, uPos)
    }
    let returnPosition = {lat: alertsMap.getCenter().lat(), lng: alertsMap.getCenter().lng(), zoom: alertsMap.getZoom() }
    return fetch(`${APIBaseUrl}/getalert/?id=${id}`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                if(!window.location.href.includes('issue')) {
                    let myLink = `/report/${id}/`;
                    history.push(myLink);
                }
                let lat = res.data[0].fields.lat;
                let lng = res.data[0].fields.lng;
                alertsMap.setZoom(14);
                setZoom(14);
                alertsMap.setCenter({lat: lat, lng: lng});
                if(marker !== null) {
                    marker.setMap(null)
                    marker = null
                }
                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(lat,lng), 
                    map: alertsMap,
                }); 
                marker.addListener("click", () => {
                    marker.setMap(null)
                    marker = null
                })
                marker.setAnimation(google.maps.Animation.DROP);
                setReportDetail([res.data[0].fields, marker, returnPosition]);
                postLog(user_id, 'getAlertForReport',  res.data[0].fields.title, subId, '', res.data[0].pk)
                return marker;
            } else if (res.status >= 400 && res.status < 500) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            }
        })
}

