import { APIBaseUrl } from "../utils/constants";

export const getLayers = async (layers, userid='', all=false) => {
    let headers = {
        "Content-Type": "application/json",
    };
    let layersParm = 'all';
    if(layers) 
        layersParm=layers;
    const response = 
        await fetch(`${APIBaseUrl}/getlayers?layers=${layersParm}&all=${all}&userid=${userid}`, {headers, });
    if(response.status < 500) {
        const data = await response.json();
        data.forEach((layer) => { layer.checked = false })
        return { data: data }
    }
}


export const getLayer = async (layers) => {
    let headers = {
        "Content-Type": "application/json",
    };
    let layersParm = 'all';
    if(layers) 
        layersParm=layers;
    const response = 
        await fetch(`${APIBaseUrl}/getlayers?layers=${layersParm}`, {headers, });
    if(response.status < 500) {
        const data = await response.json();
        return data[0]
    }
    else
        return { data: response }
}


export const deleteMyFile = (token, id, userId, setLayers) => {
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let body = JSON.stringify({ id, userId });
    return fetch(`${APIBaseUrl}/delete_myfile/`, { headers, body, method: "POST" })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                getLayers(null, userId)
                .then(res => {
                    setLayers(res.data);
                })
            } else if (res.status === 403 || res.status === 401) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            } else {
                console.log("delete myfile FAILED");
                throw res.data;
            }
        })
}


export const getGcpStorage = async (gcpBucket) => {
    let headers = {
        "Content-Type": "application/json",
        'Accept': 'application/json',
    };
    const response = await fetch(`${APIBaseUrl}/get_storage_files/?gcp_bucket=${gcpBucket}`, { headers, })
    if(response.status < 500) {
        const data = await response.json();
        return data;
    }
    else
        return { data: response }
}
