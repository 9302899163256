import { postLog } from '../utils/log';
import { APIBaseUrl } from '../utils/constants';

export const loadUser = () => {
  return  (dispatch, getState) => {
    dispatch({ type: "USER_LOADING" });

    const token = getState().auth.token;

    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
     fetch(`${APIBaseUrl}/auth/user/`, { headers, })
      .then( res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return { status: res.status, data };
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({ type: 'USER_LOADED', user: res.data });
          return res.data;
        } else if (res.status >= 400 && res.status < 500) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          throw res.data;
        }
      })
  }
}

export const login = (username, password) => {
  return (dispatch) => {
    //let headers = { "Content-Type": "application/json" };
    let headers = { 
      "Content-Type": "application/json",
      'Accept': 'application/json',
      //"Access-Control-Allow-Origin": "*",
     };

    let body = JSON.stringify({ username, password });

    return fetch(`${APIBaseUrl}/auth/login/`, { headers, body, method: "POST" })
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return { status: res.status, data };
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({ type: 'LOGIN_SUCCESSFUL', data: res.data });
          postLog(res.data.user.id, 'login', res.data.user.username);
          return res.data;
        } else if (res.status === 403 || res.status === 401 || res.status === 400) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        } else {
          // console.log(res)
          dispatch({ type: "LOGIN_FAILED", data: res.data });
        }
      })
  }
}

export const logout = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return fetch(`${APIBaseUrl}/auth/logout/`, { headers, body: "", method: "POST" })
      .then(res => {
        if (res.status === 204) {
          return { status: res.status, data: {} };
        } else if (res.status < 500) {
          return res.json().then(data => {
            return { status: res.status, data };
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 204) {
          console.log('successful logout')
          dispatch({ type: 'LOGOUT_SUCCESSFUL' });
          dispatch({ type: 'SET_SHOW_LOGIN', showLogin: false });
          window.location.reload(false);
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          //throw res.data;
        }
      })
  }
}

export const register = (username, password, first_name, last_name, email) => {
  return (dispatch) => {
    let headers = { 
      "Content-Type": "application/json",
      'Accept': 'application/json',
     };
     email = email.toLowerCase();
    return fetch(`${APIBaseUrl}/getuserbyemail/?email=${email}`, { headers, })
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return { status: res.status, data };
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {

          if (res.data.length > 0) {
            return { status: "fail", data: "This email already being used." };
          }
          else {
            let body = JSON.stringify({ username, password, first_name, last_name, email });

            return fetch(`${APIBaseUrl}/auth/register/`, { headers, body, method: "POST" })
              .then(res => {
                if (res.status < 500) {
                  return res.json().then(data => {
                    return { status: res.status, data };
                  })
                } else {
                  console.log("Server Error!");
                  throw res;
                }
              })
              .then(res => {
                if (res.status === 200) {
                  dispatch({ type: 'REGISTRATION_SUCCESSFUL', data: res.data });
                  return { status: "OK", data: "Registration completed." };
                } else if (res.status === 403 || res.status === 401) {
                  dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                  throw res.data;
                } else {
                  dispatch({ type: "REGISTRATION_FAILED", data: res.data });
                  throw res.data;
                }
              })
          }
        }
      });
  }
}

export const changePw = (username, password, token, setShowPwChange) => {
  let headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers["Authorization"] = `Token ${token}`;
  }
  let body = JSON.stringify({ username, password });
  return fetch(`${APIBaseUrl}/changepw/`, { headers, body, method: "POST" })
    .then(res => {
      if (res.status < 500) {
        return res.json().then(data => {
          return { status: res.status, data };
        })
      } else {
        console.log("Server Error!");
        throw res;
      }
    })
    .then(res => {
      if (res.status === 200) {
        return { status: "OK", data: "Password updated." };
      } else if (res.status === 403 || res.status === 401) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        throw res.data;
      }
    })
}

export const sendResetLink = (username, email='') => {
  let headers = {
    "Content-Type": "application/json",
  };
  let body = JSON.stringify({ username, email });

  return fetch(`${APIBaseUrl}/sendresetlink/`, { headers, body, method: "POST" })
    .then(res => {
      if (res.status < 500) {
        return res.json().then(data => {
          return { status: res.status, data };
        })
      } else {
        console.log("Server Error!");
        throw res;
      }
    })
    .then(res => {
      if (res.status === 200) {
        return { status: res.data.status, data: res.data.message };
      } else if (res.status === 403 || res.status === 401) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        throw res.data;
      }
    })
}

export const resetPw = (username, password, a) => {
  let headers = {
    "Content-Type": "application/json",
  };
  let body = JSON.stringify({ username, password, a });

  return fetch(`${APIBaseUrl}/resetpw/`, { headers, body, method: "POST" })
    .then(res => {
      if (res.status < 500) {
        return res.json().then(data => {
          return { status: res.status, data };
        })
      } else {
        console.log("Server Error!");
        throw res;
      }
    })
    .then(res => {
      if (res.status === 200) {
        return { status: "OK", data: "Password updated." };
      } else if (res.status === 403 || res.status === 401) {
        dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
        throw res.data;
      } else {
        throw res.data;
      }
    })
}

export const getIdFromEmail = (email, send=false) => {
  let headers = {
    "Content-Type": "application/json",
  };

  return fetch(`${APIBaseUrl}/getidfromemail/?email=${email}&send=${send}`, { headers, })
    .then(res => {
      if (res.status < 500) {
        return res.json().then(data => {
          return { status: res.status, data };
        })
      } else {
        console.log("Server Error!");
        throw res;
      }
    })
    .then(res => {
      if (res.status === 200) {
        return res;
      } else {
        throw res.data;
      }
    })
}

