import { APIBaseUrl } from "../utils/constants";

export const loadFeedSource = () => {
    let headers = {
        "Content-Type": "application/json",
    };
    return fetch(`${APIBaseUrl}/getfeedsource/`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res;
            } else if (res.status >= 400 && res.status < 500) {
                // No need to dispatch here, since this API allows any
                //dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
                throw res.data;
            }
        })
}



export const loadUserProfile = (token, id) => {
    let headers = {
        "Content-Type": "application/json",
    };
    headers["Authorization"] = `Token ${token}`;
    return fetch(`${APIBaseUrl}/getuserwithprofile/?id=${id}`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res;
            } else if (res.status >= 400 && res.status < 500) {
                // No need to dispatch here, since this API allows any
                //dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
                throw res.data;
            }
        })
}

/* getOrgAlerts will get the custom alerts that are used specifically for an organization */
export const getOrgAlerts = (org) => {
    let headers = {
        "Content-Type": "application/json",
    };
    return fetch(`${APIBaseUrl}/getorgalerts/?org=${org}`, {headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res;
            } else if (res.status >= 400 && res.status < 500) {
                // No need to dispatch here, since this API allows any
                //dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
                throw res.data;
            }
        })
}


export const updateUserProfile = (token, user_id, email_selection, map_selection, layers, init_bbox) => {
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let body = JSON.stringify({ user_id, email_selection, map_selection, layers, init_bbox });
    console.log(body)
    return fetch(`${APIBaseUrl}/updateuserprofile/`, {headers, body, method: "POST"})
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                loadUserProfile(token, user_id);
                return res.status;
            } else if (res.status === 403 || res.status === 401) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            } else {
                console.log("SaveAoi FAILED");
                throw res.data;
            }
        })
}
